import { Observable, map } from 'rxjs';

export function removeNullValuesFromObject<T>() {
  return function f(source: Observable<T>): Observable<T> {
    return source.pipe(
      map((x) => {
        return Object.entries(x as Record<string, unknown>).reduce(
          (a, [k, v]) => {
            return {
              ...a,
              [k]: v === null ? undefined : v,
            };
          },
          {} as T,
        );
      }),
    );
  };
}
