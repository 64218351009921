import {
  DVH_TOOLKIT_CODE,
  FEX_TOOLKIT_CODE,
  HI_TOOLKIT_CODE,
  MEDSUPP_TOOLKIT_CODE,
  TERM_TOOLKIT_CODE,
} from '@itk/types';
import { MenuItemConfig, Toolkit } from '../types';

export class ToolkitMetadata {
  public readonly toolkits: Toolkit[] = [
    {
      label: 'FEX',
      slug: '/fex',
      code: FEX_TOOLKIT_CODE,
      defaultTool: { label: 'Quoter', slug: '/fex/quoter' },
      tools: [
        { label: 'Quoter', slug: '/fex/quoter' },
        { label: 'Quote Compare', slug: '/fex/compare' },
        { label: 'Quick Quoter', slug: '/fex/quick' },
        { label: 'Drug Lookup', slug: '/fex/druglookup' },
        { label: 'Health Cheat Sheet', slug: '/fex/cheatsheet' },
      ],
    },
    {
      label: 'Term',
      slug: '/term',
      code: TERM_TOOLKIT_CODE,
      defaultTool: { label: 'Quoter', slug: '/term/quoter' },
      tools: [
        { label: 'Quoter', slug: '/term/quoter' },
        { label: 'Quote Compare', slug: '/term/compare' },
        { label: 'Quick Quoter', slug: '/term/quick' },
        { label: 'Drug Lookup', slug: '/term/druglookup' },
        { label: 'Health Cheat Sheet', slug: '/term/cheatsheet' },
      ],
    },
    {
      label: 'Med Supp',
      defaultTool: { label: 'Quoter', slug: '/medsupp/quoter' },
      tools: [
        { label: 'Quoter', slug: '/medsupp/quoter' },
        { label: 'Quote Compare', slug: '/medsupp/compare' },
        { label: 'Quick Quoter', slug: '/medsupp/quick' },
        { label: 'Drug Lookup', slug: '/medsupp/druglookup' },
        { label: 'Health Cheat Sheet', slug: '/medsupp/cheatsheet' },
      ],
      slug: '/medsupp',
      code: MEDSUPP_TOOLKIT_CODE,
    },
    {
      label: 'DVH',
      slug: '/dvh',
      code: DVH_TOOLKIT_CODE,
      defaultTool: { label: 'Quoter', slug: '/dvh/quoter' },
      tools: [
        { label: 'Quoter', slug: '/dvh/quoter' },
        { label: 'Quote Compare', slug: '/dvh/compare' },
      ],
    },
    {
      label: 'Hospital Indemnity',
      slug: '/hi',
      code: HI_TOOLKIT_CODE,
      defaultTool: { label: 'Quoter', slug: '/hi/quoter' },
      tools: [{ label: 'Quoter', slug: '/hi/quoter' }],
    },
  ];

  public get topNavigationMenuItems(): MenuItemConfig[] {
    return [
      {
        label: 'Account',
        slug: '/account/info',
      },
      {
        label: 'Leads',
        slug: '/leads',
      },
      // {
      //   label: 'PocketUpline',
      //   slug: 'upline',
      //   isAngular: true,
      // },
      {
        label: 'CRM',
        slug: '/crm',
        isLegacy: true,
      },
      {
        label: 'Logout',
        slug: '/logout',
        isLegacy: true,
      },
    ];
  }

  public navigationMenuItems(currentToolkit: Toolkit): MenuItemConfig[] {
    const toolSlug = currentToolkit.slug;
    return [
      {
        label: 'Customize Carriers',
        slug: `${toolSlug}/settings/customization`,
      },
      {
        label: 'Settings',
        slug: `${toolSlug}/settings`,
      },
      {
        label: 'Subscription',
        slug: '/account/subscription',
      },
      {
        label: 'Account Information',
        slug: '/account/info',
      },
      {
        label: 'Agents',
        slug: '/account/agents',
      },
      {
        label: 'Leads',
        slug: '/leads',
      },
      {
        label: 'Manage Funds',
        slug: '/leads/funds',
      },
      {
        label: 'Leads Marketplace',
        slug: '/leads/marketplace',
      },
      {
        label: 'CRM',
        slug: '/crm',
        isLegacy: true,
      },
      {
        label: 'Logout',
        slug: '/logout',
        isLegacy: true,
      },
    ];
  }

  findToolkitFromSlug(slug: string): Toolkit | undefined {
    return this.toolkits.find(
      (toolkit) => toolkit.slug.toUpperCase() === slug.toUpperCase(),
    );
  }

  public get firstToolkit(): Toolkit | undefined {
    return this.toolkits[0];
  }
}

export const defaultMetadata = new ToolkitMetadata();
