import { Observable, filter } from 'rxjs';

type NonNullableType<T> = T extends null | undefined ? never : T;

export function filterUndefined<T>() {
  return function filterUndefinedPrime(
    source: Observable<T>,
  ): Observable<NonNullableType<T>> {
    return source.pipe(
      filter((v): v is NonNullableType<T> => typeof v !== 'undefined'),
    );
  };
}

export function filterNull<T>() {
  return function f(source: Observable<T>): Observable<NonNullableType<T>> {
    return source.pipe(filter((v): v is NonNullableType<T> => v !== null));
  };
}

export function filterUndefinedNull<T>() {
  return function f(source: Observable<T>): Observable<NonNullableType<T>> {
    return source.pipe(
      filter(
        (v): v is NonNullableType<T> => typeof v !== 'undefined' && v !== null,
      ),
    );
  };
}
