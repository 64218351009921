import { Injectable } from '@angular/core';
import {
  Observable,
  ReplaySubject,
  Subject,
  combineLatestWith,
  concat,
  distinctUntilChanged,
  map,
  shareReplay,
  startWith,
  takeUntil,
} from 'rxjs';
import { filterUndefined } from '@itk/custom-rx-operators';

import { MenuItemConfig } from '../types';
import { defaultMetadata, ToolkitMetadata } from './metadata.constants';

@Injectable({ providedIn: 'root' })
export class ToolkitMetadataService {
  public readonly metadata$ = new Subject<ToolkitMetadata>().pipe(
    startWith(defaultMetadata),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  public readonly toolkitGuardSink$ = new ReplaySubject<string>(1);

  public readonly topNavigationMenuItems$ = this.metadata$.pipe(
    map((metadata) => metadata.topNavigationMenuItems),
  );

  private readonly toolkitFromGuard$ = this.metadata$.pipe(
    combineLatestWith(this.toolkitGuardSink$),
    map(([data, slug]) => data.findToolkitFromSlug(slug)),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  private readonly firstToolkitFromMetadata$ = this.metadata$.pipe(
    map((metadata) => metadata.firstToolkit),
  );

  public readonly currentToolkit$ = concat(
    this.firstToolkitFromMetadata$.pipe(takeUntil(this.toolkitFromGuard$)),
    this.toolkitFromGuard$,
  ).pipe(
    distinctUntilChanged(),
    filterUndefined(),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  public navigationMenuItems$: Observable<MenuItemConfig[]> =
    this.metadata$.pipe(
      combineLatestWith(this.currentToolkit$),
      map(([metadata, toolkit]) => metadata.navigationMenuItems(toolkit)),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

  allToolkitSlugs$: Observable<string[]> = this.metadata$.pipe(
    map((metadata) => metadata.toolkits),
    map((toolkits) => toolkits.map((toolkit) => toolkit.slug)),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );
}
